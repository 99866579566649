.header {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  // opacity: .5;
  display: flex;
  box-shadow: 2px, 2px, 2px, 4px rgba($color: #000000, $alpha: 0.5);
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  max-width: 100%;
  height: 7.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  position: sticky;
  top: 0;
  z-index: 12;

  & a.active {
    color: $main-color-hover !important;
  }
}

.backHomeHeader {
  font-size: 1.15rem;
  text-decoration: none;
  color: $second-color;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    color: $main-color-hover;
    cursor: pointer;
  }
}

.headerHome,
.headerShop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 30%;

  .siteHeadlines {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // width: 60%;

    & > div {
      font-size: 1.15rem;
      text-decoration: none;
      color: $second-color;
      padding-left: 1rem;
      padding-right: 1rem;

      &:hover {
        color: $main-color-hover;
        cursor: pointer;
      }
    }
    .shop {
      font-size: 1.35rem;
      font-weight: 500;
      text-decoration: none;
      color: $second-color;
      margin-left: 1.5rem;
      margin-right: 0.5rem;

      &:hover {
        color: $main-color-hover;
        cursor: pointer;
      }
    }
  }
}

.headerRight {
  // margin-right: 2rem;

  .headerLocation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > span {
      padding-left: 0.5rem;
      color: $second-color;
    }
  }
}

@media (min-width: 600px) {
  .headerRight {
    margin-right: 2rem;

    .headerLocation {
      flex-direction: row;
    }
  }
}
