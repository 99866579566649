.shopPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    h1{
        font-weight: 500;
        font-size: 4rem;
        text-shadow: -1px 1px 1px $second-color;
    }
}