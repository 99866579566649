.modal {
  height: 100vh !important;
  width: 100vw !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  // background: rgba(100, 100, 100, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  border: 2px solid $main-color;
  border-radius: 5px;
  background: white;
  padding: 1rem;

  textarea {
    width: 100%;
    resize: none;
    padding: 0.5rem;
  }

  h4 {
    text-align: center;
    font-size: 1.25rem;
  }

  button {
    border: none;
    color: white;
    margin: 1rem;
    border-radius: 5px;
    background: $main-color;
    padding: 0.5rem;

    &:hover {
      background: $main-color;
      cursor: pointer;
    }
  }
}

@media (min-width: 768px) {
  .modal-content {
    width: 40%;
    h4 {
      text-align: center;
      font-size: 1.45rem;
    }

    button {
      padding: 0.8rem;
      font-size: larger;
    }
  }
}
