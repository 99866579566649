$main-color: #1b3159;
$second-color: #e1c591;
$main-color-hover: #fd4e4e;
$text-primary: #151515;
$text-secondary: #444;
$primary-color: #fff;
$secondary-color: #999;
$color-text: #e1c591;
$color-bg: #fcfbfa;
$color-link: #b19e7f;
$color-link-hover: #000;
