.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  padding: 10px;
  bottom: 1rem;

  .stars {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .copyright {
    margin-top: 1rem;
    font-weight: 500;
  }
}
@media (min-width: 600px) {
  .footer {
    // width: unset;
  }
}
@media (min-width: 800px) {
  .footer {
    width: 85vw;
    // margin-left: 5.5rem;
  }
}
@media (min-width: 1000px) {
  .footer {
    width: 85vw;
    // margin-left: 5.5rem;
  }
}
