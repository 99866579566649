.container {
  position: relative;
  display: flex;
  width: 100%;
  // height: 2850px;
  // position: relative;
}

.circular-text {
  transform-origin: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.text {
  font-family: "Fira Code";
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  // min-height: 150px;
  display: flex;
  color: darken($color: $color-text, $amount: 20%);
}

.arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  margin-top: 1rem;
  right: 50px;
  bottom: 50px;
}

.arrow svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: darken($color: $second-color, $amount: 20%);
}
